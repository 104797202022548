import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";
// import Home1 from "./Pages/Home1";
// import Home from "./Pages/Home";
// import FullScreensDialog from "./Components/BarChartByDate/FullScreensDialog";
// import FullScreensDialogAnt from "./Components/BarChartByDate/FullScreensDialogAnt";
// import SummarizedDownloadChart from "./Components/SummarizedCharts/SummarizedDownloadChart";
// import SummarizedLayout from "./Components/SummarizedCharts/SummarizedLayout";
import Layout from "../src/Components/Layout/Index";

// 🔴🔥

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Layout />} />
          {/* <Route path="/test-popup" element={<FullScreensDialog />} />
          <Route path="/test-popup-ant" element={<FullScreensDialogAnt />} />

          <Route path="/sum-download" element={<SummarizedDownloadChart />} />
          <Route path="/sum-layout" element={<SummarizedLayout />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
