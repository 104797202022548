import getRandomColor from './getRandomColor';

export const color = (label) => {
    switch (label) {
        case 'THE EAST - POC 1':
            return '#DC143C'
    
        case 'THE EAST - POC 2':
            return '#0000FF'
        
        case 'THE BOULEVARD - POC 1':
            return '#FFA500'

        default:
            return getRandomColor()
    }
}