import { DatePicker } from "antd";
import dayjs from "dayjs";
import "../../styles/date-picker-styles.css";

const DatePickerComp = ({ onChange, dateFilter }) => {
  const monthFormat = 'YYYY-MM';

  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <DatePicker value={ dateFilter ? dayjs(dateFilter) : dayjs()} onChange={onChange} format={monthFormat} picker="month" />
    </div>
  );
};

export default DatePickerComp;
