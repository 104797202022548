import React from "react";

function ChartTitle({ text }) {
  return (
    <div>
      <p
        style={{
          color: "#15325b",
          fontWeight: "bold",
          fontSize: "15px",
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default ChartTitle;
