import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import dayjs from 'dayjs';
import chartjsPluginDatalabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import FullScreensDialogAnt from "../BarChartByDate/FullScreensDialogAnt";
import { color } from '../../utils/mappingColor'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  chartjsPluginDatalabels
);

function MainBarChart({ 
  chartData = [], 
  loading = false, 
  width, 
  height,
  flag,
  metaData,
  dateFilter,
  unit,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [endOfDate, setEndOfDate] = useState(0);
  const [dateSelected, setDateSelected] = useState(null);
  const [labelSelected, setLabelSelected] = useState(null);

  useEffect(() => {
    const currentDate = dayjs(dateFilter);
    const lastDateOfMonth = currentDate.endOf('month');
    setEndOfDate(dayjs(lastDateOfMonth).date());
  }, [ dateFilter ])

  const chartRef = useRef();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elems) => {
      if (elems.length > 0) {
        const datasetIndex = elems[0].datasetIndex;
        const dataIndex = elems[0].index;
        const dataset = data.datasets[datasetIndex]
        const rawDate = dataIndex+1
        const date = `${dayjs(dateFilter).format('YYYY-MM')}-${ rawDate < 10 ? `0${rawDate}`: rawDate }`
        const label = dataset.label
        setDateSelected(date)
        setLabelSelected(label)

        setIsModalOpen(true);
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function(tooltipItem, data) {
            return `Min: ${tooltipItem.dataset.dataMin[tooltipItem.dataIndex]}; Avg: ${tooltipItem.dataset.data[tooltipItem.dataIndex].toFixed(2)}; Max: ${tooltipItem.dataset.dataMax[tooltipItem.dataIndex]};`
          }
        },
        multiKey: true
      },
      legend: {
        position: "top",
      },
      datalabels: {
        display: true,
        color: 'white',
        font: {
          size: 9
        },
        formatter: function(value, context) {
          return value + `\n${unit}`;
        },
        anchor: "end",
        align: "start",
        textAlign: "center"
      }
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },

      y: {
        grid: {
          drawOnChartArea: true,
        },
      },
    }
  };

  const labels = () => {
    const result = []
    for (let i = 0; i < endOfDate; i++) {
      result.push(i + 1)
    }
    return result
  }

  const datasets = []
  const venues = []

  chartData.forEach(item => {
    const findVenues = venues.find(f => f === item._id.venue)
    if (!findVenues) {
      venues.push(item._id.venue)
    }
  })

  for (let i = 0; i < venues.length; i++) {
    const item = venues[i]

    const label = item
    const backgroundColor = color(item)
    const borderRadius = 2.5
    const data = []
    const dataMax = []
    const dataMin = []
    for (let j = 0; j < endOfDate; j++) {
      const getVenues = chartData.filter(cd => cd._id.venue === item)

      const venueWithExactDate = getVenues.find(gv => {
        const dateVenue = dayjs(gv._id.date).date()
        return Number(j + 1) === Number(dateVenue)
      })

      if (!venueWithExactDate) {
        data.push(0)
        dataMax.push(0)
        dataMin.push(0)
      } else {
        if (flag === 'upload') {
          data.push(venueWithExactDate.upload_avg.toFixed(2))
          dataMax.push(venueWithExactDate.upload_max.toFixed(2))
          dataMin.push(venueWithExactDate.upload_min.toFixed(2))
        }

        if (flag === 'download') {
          data.push(venueWithExactDate.download_avg.toFixed(2))
          dataMax.push(venueWithExactDate.download_max.toFixed(2))
          dataMin.push(venueWithExactDate.download_min.toFixed(2))
        }

        if (flag === 'ping') {
          data.push(venueWithExactDate.ping_avg.toFixed(2))
          dataMax.push(venueWithExactDate.ping_max.toFixed(2))
          dataMin.push(venueWithExactDate.ping_min.toFixed(2))
        }

      }
    }

    datasets.push({ label, backgroundColor, borderRadius, data, dataMax, dataMin })
  }

  const data = {
    labels: labels(),
    datasets,
  }

  return (
    <>
      {!loading ? (
        <>
          {" "}
          <Bar
            options={options}
            data={data}
            ref={chartRef}
            width={width}
            height={height}
          />
          <FullScreensDialogAnt
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            dateSelected={dateSelected}
            labelSelected={labelSelected}
            flag={flag}
          />
        </>
      ) : (
        <>loading....</>
      )}{" "}
    </>
  );
}

export default MainBarChart;
