import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import "tabler-react/dist/Tabler.css";
import ReactToPrint from 'react-to-print'

import {
  Button,
  Divider,
  Select,
} from "antd";

import DatePickerComp from "../Components/DatePicker/DatePicker";
import MainBarChart from "../Components/MainBarChart/MainBarChart";
import ChartTitleBig from "../Title/ChartTitleBig";
import ChartTitle from "../Title/ChartTitle";
import dayjs from "dayjs";


const chartWrapper = {
  margin: "10px 0px",
  borderRadius: "20px",
  padding: "10px",
  backgroundColor: "white",
  boxShadow: `1px 1px 2px 0px rgba(21, 50, 91, 0.12),
          1px 1px 2px 0px rgba(21, 50, 91, 0.12),
          1px 1px 2px 0px rgba(21, 50, 91, 0.12)`,
};

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [dateFilter, setDateFilter] = useState(null);
  const [venues, setVenues] = useState([])
  const [selectedVenue, setSelectedVenue] = useState();

  const contentChartRef = useRef(null)

  const onChange = async (date, dateString) => {
    const year = dayjs(date).year()
    const monthRaw = dayjs(date).month() + 1
    const month = monthRaw < 10 ? `0${monthRaw}` : `${monthRaw}`

    await fetchData(month, year, selectedVenue ? selectedVenue : undefined)
    setDateFilter(date)
  };

  const fetchData = async (monthParam, yearParam, venue) => {
    const monthDefault = dayjs().month() + 1
    const yearDefault = dayjs().year()
    const formatMonth = monthDefault < 10 ? `0${monthDefault}` : `${monthDefault}`

    let month = formatMonth
    let year = yearDefault

    if (monthParam && yearParam) {
      month = monthParam
      year = yearParam
    }

    setLoading(true)
    const { data: response } = await axios.get(`${process.env.REACT_APP_SPEEDTEST_URI}/monitoring/average?year=${year}&month=${month}&venue=${venue}`)
    const { data } = response

    setChartData(data.data)
    setMetaData(data.meta)
    setDateFilter(dayjs(`${year}-${month}`))
    setLoading(false)
  }

  const fetchVenue = async () => {
    try {
      const { data: response } = await axios.get(`${process.env.REACT_APP_SPEEDTEST_URI}/monitoring/venue`)
      const { data: venue } = response

      setVenues(venue.map(item => ({ value: item, label: item })))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchVenue()
  }, [])

  const onChangeSelectVenue = async (value) => {
    const year = dayjs(dateFilter).format('YYYY')
    const month = dayjs(dateFilter).format('MM')
    setSelectedVenue(value)

    if (!value) {
      await fetchData(month, year)
    } else {
      await fetchData(month, year, value)
    }
  }

  return (
    <div style={{ padding: "0px 30px" }}>
      <div style={{ display: "flex", justifyContent: "end", gap: "2em", alignContent: "center", alignItems: "center" }}>
        <div>
          <DatePickerComp
            dateFilter={dateFilter}
            onChange={onChange}
          />
        </div>

        <div>
          <Select
            options={venues}
            placeholder="Select Venue"
            onChange={onChangeSelectVenue}
            style={{ width: "12.5rem" }}
            size="large"
            allowClear
          />
        </div>

        <div>
          <ReactToPrint
            trigger={() => <Button type="primary"> Download as PDF </Button>}
            content={() => contentChartRef.current}
          />
          
        </div>
      </div>

      <div ref={contentChartRef}>
        <ChartTitleBig text={"Chart in a Month"} />

        <div style={chartWrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "10px",
            }}
          >
            <ChartTitle text={`${metaData ? metaData.title : ''} - Average Upload Speed`} />
          </div>
          <div>
            <MainBarChart
              loading={loading}
              chartData={chartData}
              metaData={metaData}
              width={100}
              height={350}
              flag={"upload"}
              dateFilter={dateFilter}
              unit={"Mbps"}
            />
          </div>
        </div>

        <Divider style={{ marginBottom: "30px" }} />

        <div style={chartWrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "10px",
            }}
          >
            <ChartTitle text={`${metaData ? metaData.title : ''} - Average Download Speed`} />
          </div>
          <div>
            <MainBarChart
              loading={loading}
              chartData={chartData}
              metaData={metaData}
              width={100}
              height={350}
              flag={"download"}
              dateFilter={dateFilter}
              unit={"Mbps"}
            />
          </div>
        </div>

        <Divider style={{ marginBottom: "30px" }} />

        <div style={chartWrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              paddingLeft: "10px",
            }}
          >
            <ChartTitle text={`${metaData ? metaData.title : ''} - Average Ping`} />
          </div>
          <div>
            <MainBarChart
              loading={loading}
              chartData={chartData}
              metaData={metaData}
              width={100}
              height={350}
              flag={"ping"}
              dateFilter={dateFilter}
              unit={"ms"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home