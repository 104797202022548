import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";
import { color } from '../../utils/mappingColor'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChartDownloadByDate ({
  chartData = [],
  flag,
  labelSelected,
}) {
  const [ labels ] = React.useState([])
  const [ datasets ] = React.useState([ {
    label: labelSelected,
    backgroundColor: color(labelSelected),
    borderRadius: 1.5,
    data: [],
  } ])

  for (let i = 0; i < 24; i++) {
    labels.push(i === 0 ? `00:00` : ( i > 0 && i < 10) ? `0${i}:00` : `${i}:00`)

    const findTime = chartData.find(item => dayjs(item.timestamp).hour() === i)
    
    if (findTime) {
      if (flag === 'download') {
        datasets[0].data.push(findTime.downloadMbps)
      }

      if (flag === 'upload') {
        datasets[0].data.push(findTime.uploadMbps)
      }

      if (flag === 'ping') {
        datasets[0].data.push(findTime.ping)
      }
    } else {
      datasets[0].data.push(0)
    }
  }

  const data = {
    labels,
    datasets,
  }

  const chartRef = React.useRef();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },

      y: {
        grid: {
          drawOnChartArea: true,
        },
      },
    }
  };

  return (
    <>
      <div
        style={{
          height: "80%",
          width: "90%",
        }}
      >
        <Bar
          options={options}
          data={data}
          ref={chartRef}
          width={100}
          height={350}
        />
      </div>
    </>
  )
}

export default BarChartDownloadByDate;
