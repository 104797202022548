const getRandomColor = () => { //generates random colours and puts them in string
  let color = '#'
  const letters = '0123456789ABCDEF'.split('');
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color
}

export default getRandomColor