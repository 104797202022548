import React from "react";
import { Divider, Typography } from "antd";
const { Title } = Typography;
function ChartTitle({ text }) {
  return (
    <>
      <Divider style={{ marginBottom: "30px" }} orientation="left">
        <Title style={{ color: "#15325b" }} level={3}>
          {text}
        </Title>
      </Divider>
    </>
  );
}

export default ChartTitle;
