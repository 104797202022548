import "../../styles/ant-modal.css";
import React from 'react';
import { Modal } from "antd";
import axios from 'axios'

import BarChartDownloadByDate from "./BarChartDownloadByDate";

function FullScreensDialogAnt({
  isModalOpen,
  setIsModalOpen,
  dateSelected,
  labelSelected,
  flag,
}) {
  const [loading, setLoading] = React.useState(false);
  const [chartData, setChartData] = React.useState([]);

  const fetchData = async () => {
    setLoading(true)

    const { data: response } = await axios.get(
      `${process.env.REACT_APP_SPEEDTEST_URI}/monitoring/detail/date?date=${dateSelected}&venue=${labelSelected}`
    )

    setChartData(response.data)
    setLoading(false)
  }

  React.useEffect(() => {
    if (isModalOpen) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [isModalOpen])

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        {
          loading ? <>
            loading . . .
          </> : 
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BarChartDownloadByDate
              chartData={chartData}
              flag={flag}
              labelSelected={labelSelected}
            />
          </div>
        }
      </Modal>
    </>
  )
}

export default FullScreensDialogAnt
