import { useState } from 'react'
// import {
//   FileOutlined,
//   PieChartOutlined,
//   UserOutlined,
//   DesktopOutlined,
//   TeamOutlined,
// } from "@ant-design/icons";
import {
  // Breadcrumb,
  Layout,
  Menu,
  // theme
} from "antd";
import Home from '../../Pages/Home';

const {
  Header,
  Content,
  // Footer,
  Sider
} = Layout;
const navItems = []
// function getItem(label, key, icon, children) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//   };
// }
const sidebarItems = [
  // getItem("Option 1", "1", <PieChartOutlined />),
  // getItem("Option 2", "2", <DesktopOutlined />),
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  // getItem("Team", "sub2", <TeamOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  // getItem("Files", "9", <FileOutlined />),
];

const LayoutComponent = () => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint="lg"
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.2)",
            position: "sticky",
            left: 0,
            top: 16,
            bottom: 0,
            zIndex: 10,
          }}
        />
        <div
          style={{
            marginTop: "100px",
            width: collapsed ? "80px" : "200px",
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={sidebarItems}
          />
        </div>
      </Sider>

      <Layout className='site-layout'>
        <Header
          className="header"
          style={{
            padding: 0,
          }}
        >
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["2"]}
            items={navItems}
          />
        </Header>

        <Content
          style={{
            margin: "0px 0px 0px 0px",
            padding: "0px 0px 0px 0px",
          }}
        >
          <div
            style={{
              padding: "20px 0px",
              minHeight: "360px",
              backgroundColor: "rgba(141, 197, 248, 0.2)",
            }}
          >
            <Home />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent
